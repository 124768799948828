import { render, staticRenderFns } from "./AffiliateHead.vue?vue&type=template&id=7080d492&scoped=true&"
import script from "./AffiliateHead.vue?vue&type=script&lang=js&"
export * from "./AffiliateHead.vue?vue&type=script&lang=js&"
import style0 from "./AffiliateHead.vue?vue&type=style&index=0&id=7080d492&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7080d492",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,Head: require('/home/php1/m-nuxt/components/Head.vue').default,YfnCell: require('/home/php1/m-nuxt/components/YfnCell.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
