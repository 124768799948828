
export default {
    name: 'AffiliateHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            hasQuick: true,
            popover: false,
            user: {},
            popupCompo: {
                visible: false
            },
            log: {
                visible: false,
                list: [],
                cell: {
                    border: false
                }
            },
            cellCompo: {
                id: '',
                list: [],
                cell: {
                    border: false
                }
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取用户i信息
        getUserInfoAjax() {
            if(!this.user.id){
                return
            }
            this.$api.user.getUseInfo({
                id: this.user.id
            }).then(response => {
                const res = response.result || {};
                this.user = res || {}
            })
        },
        handleQuick() {
            this.popupCompo.visible = true
        },
        handleClose() {
            this.popupCompo.visible = false
        },
        handleIcon() {
            const name = this.$route.name
            const query = this.$route.query
            const type = query.type || 1
            if(['AffiliateCreate'].includes(name)) {
                const v = ['Affiliate', 'DropShipping'][type - 1]
                this.$router.push({
                    name: v
                })
            }
        },
        handleCell(obj) {
            const name = this.$route.name
            this.popupCompo.visible = false
            if(obj.href) {
                window.location.href = obj.href
            } else {
                if(name != obj.url) {
                    this.$router.push({
                        name: obj.url
                    })
                }
            }
        },
        handleLog() {
            this.log.visible = true
        },
        handleLogin(obj) {
            this.$router.push({
                name: 'AffiliateLogin',
                query: {
                    type: obj.type
                }
            })
        },
        handleLogOut() {
            const {name, query} = this.$route
            this.$storage.remove('token');
            this.$storage.remove('productInfo');
            this.$storage.remove('email');
            this.$storage.remove('chooseCouponInfo')
            this.$storage.remove('oneId');
            this.$storage.remove('twoId');
            if(!['Affiliate', 'DropShipping'].includes(name)) {
                let jumpName = ''
                if(query.type == 1 || ['AffiliateAccount'].includes(name)) {
                    jumpName = 'Affiliate'
                } else {
                    jumpName = 'DropShipping'
                }
                this.$router.push({
                    name: jumpName
                })
                return
            }
            this.$router.go(0)
        },
        getContainer() {
            const tag = document.querySelector('.AffiliateHead')
            return tag
        },
        getContainerUser() {
            const tag = document.querySelector('.AffiliateHead-user')
            return tag
        },
        // 初始化
        initialSet() {
            const name = this.$route.name
            this.hasLogin = this.$login()
            this.user.id = this.$storage.get('userId');
            this.hasQuick = ['Affiliate', 'DropShipping'].includes(name)
            this.cellCompo.id = name
            this.cellCompo.list = [{
                title: this.$translate('Affiliate'),
                url: 'Affiliate'
            }, {
                title: this.$translate('Drop Shipping'),
                url: 'DropShipping'
            }, {
                title: this.$translate('Blog'),
                href: 'https://blog.yfn.com/'
            }, {
                title: this.$translate('Contact Us'),
                url: 'ContactUs'
            }]
            this.log.list = [{
                title: this.$translate('Affiliate Center'),
                type: 1
            }, {
                title: this.$translate('Drop Shipping'),
                type: 2
            }]
            this.hasLogin && this.getUserInfoAjax()
        }
    },
}
